.facebook-search-container {
    background-color: #1a1a1a;
    color: #33ff33;
    font-family: 'Courier New', Courier, monospace;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

.search-form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.search-form label {
    margin-bottom: 10px;
    font-size: 18px;
}

.search-form input {
    padding: 10px;
    border-radius: 4px;
    border: 2px solid #33ff33;
    background-color: #ffffff;
    color: #095009;
    margin-bottom: 10px;
    font-size: 16px;
}

.search-form-button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #33ff33;
    color: #000;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.search-form-button:hover {
    background-color: #186818;
}

.results-container {
    margin-top: 20px;
}

.result-card {
    background-color: #000;
    border: 2px solid #33ff33;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

.result-card p {
    margin: 5px 0;
}

.no-data {
    text-align: center;
    font-size: 18px;
    color: #ff3333;
}

.matrix-bg {
  background-color: #000;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.matrix-login-container {
  width: 300px;
  padding: 20px;
  background-color: #0f0f0f;
  border: 2px solid #0f0f0f;
  border-radius: 10px;
  box-shadow: 0 0 10px #00ff00;
}

.matrix-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.matrix-password-input {
  background-color: #000;
  color: #00ff00;
  border: 2px solid #00ff00;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 16px;
  margin-bottom: 10px;
  width: 100%;
}

.matrix-password-input::placeholder {
  color: #00ff00;
  opacity: 0.6;
}

.matrix-submit-button {
  background-color: #00ff00;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
}

.matrix-submit-button:hover {
  background-color: #00cc00;
}

.incorrect-password {
  border-color: red;
}

.incorrect-password-msg {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: -10px;
}


.msg-info-on-login {
  color: rgb(34, 255, 0) !important;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: -10px;
  cursor: pointer;
}


.colorfortele {
  color: #ad0000;
  font-weight: 600;
}

.ad-banner {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #00ff00;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  z-index: 1000;
}
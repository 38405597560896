.matrix-bg {
    background-color: #000;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.matrix-register-container {
    width: 300px;
    padding: 20px;
    background-color: #0f0f0f;
    border: 2px solid #0f0f0f;
    border-radius: 10px;
    box-shadow: 0 0 10px #00ff00;
}

.matrix-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.matrix-input {
    background-color: #000;
    color: #00ff00;
    border: 2px solid #00ff00;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
}

.matrix-input::placeholder {
    color: #00ff00;
    opacity: 0.6;
}

.matrix-submit-button {
    background-color: #00ff00;
    color: #000;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
}

.matrix-submit-button:hover {
    background-color: #00cc00;
}

.input-error {
    border-color: red;
}

.error-msg {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: -10px;
}

.notification {
    background-color: #0f0f0f;
    color: #00ff00;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.notification-buttons {
    display: flex;
    justify-content: space-between;
}


.register-link {
    color: #b3b3b3;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
}

.register-link:hover {
    text-decoration: underline;
}
/* Scoped styles for the ShowFamily component */
.showFamily-container {
    background-color: #000;
    color: #00ff00;
    font-family: 'Courier New', Courier, monospace;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
}

.showFamily-table-container {
    overflow-x: auto;
    width: 100%;
}

.showFamily-table {
    width: 100%;
    border-collapse: collapse;
    color: #00ff00;
    border: 1px solid #00ff00;
}

.showFamily-th,
.showFamily-td {
    padding: 15px;
    text-align: left;
    border: 1px solid #00ff00;
}

.showFamily-thead {
    background-color: #333;
}

.showFamily-th {
    font-weight: bold;
}

@media (max-width: 768px) {
    .showFamily-table {
        display: block;
        width: 100%;
        border: 0;
    }

    .showFamily-thead {
        display: none;
    }

    .showFamily-tbody {
        display: block;
    }

    .showFamily-tr {
        display: block;
        margin-bottom: 10px;
        border-bottom: 1px solid #00ff00;
    }

    .showFamily-td {
        display: block;
        text-align: right;
        position: relative;
        padding: 10px;
        border-bottom: 1px solid #00ff00;
        white-space: nowrap;
    }

    .showFamily-td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 10px;
        font-weight: bold;
        background-color: #000;
        color: #00ff00;
        border-right: 1px solid #00ff00;
        text-align: left;
    }

    .showFamily-td {
        padding-left: 50%;
    }
}
.navbar-link {
  text-decoration: none;
  color: white;
  margin: 0 10px;
  font-size: 18px;
  transition: color 0.3s;
}

.navbar-link:hover {
  color: #ffeb3b;
}

.prominent {
  font-weight: bold;
  color: gold;
}

.desktop-menu {
  display: flex;
}

@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }
}
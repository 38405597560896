@import '~bootstrap/dist/css/bootstrap.min.css';

.container-fluid {
    background-color: #000 !important;
}

body {
    direction: rtl;
}

html,
body {
    height: 100%;
    margin: 0;
}

.dashboard-content {
    background-color: #000;
    color: #00ff00;
    font-family: 'Courier New', Courier, monospace;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
}

.search-inputs {
    margin-top: 20px;
}


/* notefactions */
.login-page {
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #00ff00;
    font-family: 'Courier New', Courier, monospace;
}

.notification {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #111;
    color: #00ff00;
    padding: 20px;
    border: 1px solid #00ff00;
    border-radius: 5px;
    width: 90%;
    max-width: 600px;
    text-align: center;
    z-index: 1000;
}

.notification p {
    margin: 0;
    padding: 0 10px;
}

.notification-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.notification-buttons button {
    background-color: #00ff00;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
    margin: 5px;
}

.notification-buttons button:hover {
    background-color: #555;
}

@media (max-width: 600px) {
    .notification {
        width: 100%;
        padding: 15px;
    }

    .notification-buttons button {
        padding: 10px;
        font-size: 14px;
    }
}
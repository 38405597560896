
.info-page .form-group {
    margin-bottom: 15px;
}

.info-page label {
    display: block;
    margin-bottom: 5px;
}

.info-page input[type="text"],
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #00ff00;
    border-radius: 5px;
    background-color: #111;
    color: #00ff00;
}

.info-page button {
    background-color: #00ff00;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.info-page button:disabled {
    background-color: #555;
    cursor: not-allowed;
}

.info-page p {
    color: #00ff00;
}
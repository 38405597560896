/* General styles */
.list-view {
    padding: 15px;
    overflow-y: auto;
}

.table-container {
    overflow-y: auto;
}

.no-data-message {
    text-align: center;
    margin-top: 20px;
}

.table {
    width: 100%;
    overflow-x: auto;
}

/* Responsive styles for table */
@media (max-width: 768px) {
    .table {
        display: block;
        overflow-x: auto;
    }

    .table thead {
        display: block;
    }

    .table thead th {
        display: none;
    }

    .table tr {
        display: block;
        margin-bottom: 15px;
    }

    .table tbody tr {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 15px;
    }

    .table td {
        display: flex;
        justify-content: space-between;
        text-align: right;
        position: relative;
        padding-left: 10px;
        white-space: pre-wrap;
        width: 100%;
    }

    .table td::before {
        content: attr(data-label);
        font-weight: bold;
    }
}